import router from "@/router";
export default {
    url:{
        api:"https://crm.mobixgroup.com",
        app:"https://app.crm-move.com",
        socket: 'https://socket.mobixgroup.com',
    },    
    user: null,        
    params: false,
    menu: [],
    logo:'/img/logo.png',    
    lang: localStorage.lang === undefined ? 'fr' : localStorage.lang,    
    ajax(route, params = null, callBack) {
        if(route === undefined){
            return false;
        }
        const url = route.search('http') === 0 ? route : this.url.api + route;
        let the = this;
        let option = {};
        option.headers = {
          "Content-Type":"application/json",
          Authorization: this.getToken()
        }
        if(params != null){
          option.method = "POST",
          option.body = JSON.stringify(params)
        }
        fetch(url,option).then( response => {
          if(response.status === 401){
            the.destroyToken();
            the.destroyUser();
            //router.push({path:'/login'})
            if(router.currentRoute.name === 'login' || router.currentRoute.name === null){
              return false;
            }else{
              router.push({path:'/login'})
            }
          }
          response.json().then( data =>{
            the.res = data;
            if(data.status === false){
              this.toast.toast(data.data, {
                title: 'Erreur',
                variant: 'danger',
                solid: true
              });
            }
            if(callBack != null) {
              callBack(the.res);
            }
          })
        }).catch( res => {
          console.log(res);
        });
    },  
    getToken(){
        var t = window.localStorage.getItem("token");
        if(t === undefined || t === null){
            return false;
        }
        return t;
    },
    saveToken(token){
        window.localStorage.setItem("token", token);
    },
    saveUser(user){
        this.user = user;
    },
    getUser(){
        return this.user;
    },
    destroyToken(){
        window.localStorage.removeItem("token");
    },
    destroyUser(){
        this.user = null;
    },
    timestampToDate(timestamp,datetime = true){
        var date = new Date(timestamp*1000);
        var res = date.toLocaleDateString("fr-Fr");
        if(datetime){
          res += ' '+date.toLocaleTimeString("fr-Fr");
        }
        return res;
    },
    now(){
        var n = new Date().getTime();
        n = n/1000;
        return parseInt(n);
    },    
    getSchema(name){
        var check = this.schema[name];
        if(check === undefined){
          return {}
        }
        return JSON.parse(JSON.stringify(check));
    },
    initSchema(){
        if(this.schema.UserRole === undefined){
            this.ajax('/magic/schema', null, (res) => {
                if(res.status){
                    this.schema = res.data
                }
            })
        }
    },         
    acl(id){
    var check = this.user.acl.find(a => a === id);
    if(check === undefined){
        return false;
    }else{
        return true;
    }
    },
    q(param, id){
        if(this.params[param] === undefined){
            return {}
        }
        var check = this.params[param].find(p => p.id === id);
        if(check === undefined){
            return {titre:"Non defini"}
        }
        return check
    },    
    p(v){
        if(v === null || v === undefined){
            return this.p(0);
        }
        return v.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        });
    },
    link(path){
      return this.url.api+path;
    }, 
}
