import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import vuetify from "@/core/plugins/vuetify";
import '@/core/plugins/bootstrap';
import "@/core/plugins/apexcharts";
import "@/core/plugins/leaflet";
import "@/core/plugins/treeselect";
import moment from 'moment'
moment.locale("fr");
Vue.config.productionTip = false
import Api from '@/core/Api';
Vue.prototype.$api = Api;
Api.moment = moment;
new Vue({
  router,
  vuetify,
  render: h => h(App),
  beforeMount(){
    Api.toast = this.$bvToast;        
  }
}).$mount('#app')
