import Vue from 'vue'
import VueRouter from 'vue-router'
import Api from '@/core/Api';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/Login.vue')
  }, 
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/views/layout/Main"),
    children: [
      {
        path:"test",
        name:"test",
        component: () => import("@/views/Test.vue"),
        meta: {
          logged: true
        }
      },
      {
        path: "/magic",
        name: "magic",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "model/:name",
            name: "magic-model",
            component: () => import("@/views/magic/Model.vue"),
            meta: {              
              logged: true
            }
          }              
        ]
      },
      {
        path: "/prospect",
        name: "prospect",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "prospect-liste",
            component: () => import("@/views/prospect/Liste.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "nouveau",
            name: "prospect-nouveau",
            component: () => import("@/views/prospect/Nouveau.vue"),
            meta: {              
              logged: true
            }
          },              
          {
            path: "detail/:id",
            name: "lead-detail",
            component: () => import("@/views/prospect/Nouveau.vue"),
            meta: {              
              logged: true
            }
          }              
        ]
      },
      {
        path: "/devis",
        name: "devis",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "devis-liste",
            component: () => import("@/views/devis/Liste.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "nouveau/:id",
            name: "devis-nouveau",
            component: () => import("@/views/devis/Nouveau.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "detail/:id",
            name: "devis-detail",
            component: () => import("@/views/devis/Nouveau.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "planning",
            name: "devis-planning",
            component: () => import("@/views/devis/Planning.vue"),
            meta: {              
              logged: true
            }
          }              
        ]
      },
      {
        path: "/facture",
        name: "facture",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "facture-liste",
            component: () => import("@/views/facture/Liste.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "nouveau",
            name: "facture-nouveau",
            component: () => import("@/views/facture/Nouveau.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "detail/:id",
            name: "facture-detail",
            component: () => import("@/views/facture/Nouveau.vue"),
            meta: {              
              logged: true
            }
          }          
        ]
      },
      {
        path: "/avoir",
        name: "avoir",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "avoir-liste",
            component: () => import("@/views/avoir/Liste.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "nouveau",
            name: "avoir-nouveau",
            component: () => import("@/views/avoir/Nouveau.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "detail/:id",
            name: "avoir-detail",
            component: () => import("@/views/avoir/Nouveau.vue"),
            meta: {              
              logged: true
            }
          }          
        ]
      },
      {
        path: "/utilisateur",
        name: "utilisateur",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "utilisateur-liste",
            component: () => import("@/views/utilisateur/Liste.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "nouveau",
            name: "utilisateur-nouveau",
            component: () => import("@/views/utilisateur/Nouveau.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "detail/:id",
            name: "utilisateur-detail",
            component: () => import("@/views/utilisateur/Nouveau.vue"),
            meta: {              
              logged: true
            }
          },          
        ]
      },
      {
        path: "/sav",
        name: "sav",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "sav-liste",
            component: () => import("@/views/sav/Liste.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "nouveau/:id",
            name: "sav-nouveau",
            component: () => import("@/views/sav/Nouveau.vue"),
            meta: {              
              logged: true
            }
          },
          {
            path: "detail/:id",
            name: "sav-detail",
            component: () => import("@/views/sav/Nouveau.vue"),
            meta: {              
              logged: true
            }
          }             
        ]
      },
      {
        path: "/tool",
        name: "tool",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "tool-liste",
            component: () => import("@/views/tool/Liste.vue"),
            meta: {              
              logged: true
            }
          }          
        ]
      },
      {
        path:'/agenda',
        name: 'Agenda',
        component: () => import( "@/views/agenda/Main.vue"),
        children:[
          {
            path:'mois',
            name:'Agenda par mois',
            component: () => import( "@/views/agenda/Mois.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'semaine',
            name:'Agenda par semaine',
            component: () => import( "@/views/agenda/Semaine.vue"),
            meta:{
              logged:true
            }
          }          
        ]
      }, 
      {
        path: "/statistique",
        name: "statistique",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "",
            name: "statistique-liste",
            component: () => import("@/views/statistique/Main.vue"),
            meta: {              
              logged: true
            }
          }          
        ]
      },
    ]
  }, 
  {
    path:"/inventaire/:code",
    name:"test",
    component: () => import("@/views/devis/Inventaire.vue"),
    meta: {
      logged: false
    }
  },
  { 
    path: "*",
    redirect: "/statistique"
  }, 
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

async function backVerify(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };  
  var url = Api.url.api + '/authorization/verify';
  var response = await fetch(url,option);
  var res = await response.json();  
  return res;
}

async function init(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };
  var url = Api.url.api + '/magic/init'
  var response = await fetch(url,option);
  var res = await response.json();
  return res;
}

router.beforeEach( async (to, from, next) => {  
  if(to.meta.logged === true){    
    if(Api.params === false && Api.getToken() != false){
      await init().then(res => {
        if(res.status){                  
          Api.params = res.data.params;
          Api.menu = res.data.menu;          
        }
      });
    }
    if(Api.getToken() != false){
      await backVerify().then(res => {
        if(res.status === true){
          Api.saveToken(res.data.token);
          Api.saveUser(res.data);
          next();
        }else{                    
          next({path:'/login'});          
        }
      }).catch( () => {        
        next({path:'/login'})
      });
    }else{      
      next({path:'/login', query:{redirect: to.path}})
    }
  }else{
    next();
  }
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
})
export default router
